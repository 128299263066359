import React from 'react';
import Post from '../components/Post';

function WritingInner() {
    return (
        <div>
            <Post/>
        </div>
    );
}

export default WritingInner;